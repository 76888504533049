import { useMutation, useQuery } from "@apollo/client"
import {
  AllBooksInLibraryDocument,
  AllBooksInLibraryQuery,
  BooksByCustomShelfSlugDocument,
  BooksByCustomShelfSlugQuery,
  BooksByStatusDocument,
  BooksByStatusQuery,
  MyUsernameDocument,
  ReadingStatusEnum,
  SetReadingStatusesDocument,
  SetReadingStatusesMutation,
  SetReadingStatusesMutationVariables,
} from "@/graphql/generated/types"
import { useSearchParams } from "next/navigation"
import { useUser } from "@/contexts/user"
import { LIBRARY_BOOKS_PER_PAGE } from "@/constants"
import { PERMANENT_SHELVES } from "@/constants/PERMANENT_SHELVES"

function useSetReadingStatus(bookIds: string[]) {
  const { user } = useUser()
  const currentPage = Number(useSearchParams().get("page")) || 1

  const pageSize = LIBRARY_BOOKS_PER_PAGE

  const { data: userData } = useQuery(MyUsernameDocument, {
    skip: !user,
  })

  const currentShelf = useSearchParams().get("shelf") || "ALL"

  const isPermanentShelf = (sh: string) =>
    PERMANENT_SHELVES.slice(1).find((shelf) => shelf.value === sh) as
      | (typeof PERMANENT_SHELVES)[1]
      | undefined

  const [setReadingStatusMutation] = useMutation<
    SetReadingStatusesMutation,
    SetReadingStatusesMutationVariables
  >(SetReadingStatusesDocument)

  const setStatus = async (status: ReadingStatusEnum) => {
    await setReadingStatusMutation({
      variables: {
        bookIds: bookIds,
        status: status,
      },
      optimisticResponse: {
        setReadingStatuses: {
          __typename: "StatusBooksUpdateResponse",
          successfulBooks: bookIds.length,
          totalBooksProcessed: bookIds.length,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries:
        isPermanentShelf(currentShelf) && isPermanentShelf(status)
          ? [
              {
                query: BooksByStatusDocument,
                variables: {
                  username: userData?.me?.username,
                  status: currentShelf as ReadingStatusEnum,
                  page: currentPage,
                  pageSize: pageSize,
                },
              },

              {
                query: BooksByStatusDocument,
                variables: {
                  username: userData?.me?.username,
                  status: status as ReadingStatusEnum,
                  page: currentPage,
                  pageSize: pageSize,
                },
              },
            ]
          : isPermanentShelf(status)
          ? [
              {
                query: BooksByStatusDocument,
                variables: {
                  username: userData?.me?.username,
                  status: status as ReadingStatusEnum,
                  page: currentPage,
                  pageSize: pageSize,
                },
              },
            ]
          : [],

      update: (cache, { data }) => {
        // Update the cache for each book
        bookIds.forEach((bookId) => {
          cache.modify({
            id: cache.identify({ __typename: "Book", id: bookId }),
            fields: {
              myReadingStatus: () => status,
            },
          })

          if (!data?.setReadingStatuses) return

          const username = userData?.me?.username
          if (!username) return

          if (currentShelf === "ALL") {
            try {
              const allBooksData = cache.readQuery<AllBooksInLibraryQuery>({
                query: AllBooksInLibraryDocument,
                variables: { username, page: currentPage, pageSize },
              })

              if (allBooksData?.userByUsername?.libraryBooks?.items) {
                const newItems =
                  allBooksData.userByUsername.libraryBooks.items.map((item) => {
                    if (item?.book?.id && bookIds.includes(item?.book?.id)) {
                      return {
                        ...item,
                        readingStatus: {
                          ...item.readingStatus,
                          status: status,
                        },
                      }
                    }
                    return item
                  })

                cache.writeQuery({
                  query: AllBooksInLibraryDocument,
                  variables: { username, page: currentPage, pageSize },
                  data: {
                    ...allBooksData,
                    userByUsername: {
                      ...allBooksData.userByUsername,
                      libraryBooks: {
                        ...allBooksData.userByUsername.libraryBooks,
                        items: newItems,
                      },
                    },
                  },
                })
              }
            } catch (err) {
              console.error("Error updating 'AllBooksInLibrary' cache:", err)
            }
          } else if (
            Object.values(ReadingStatusEnum).includes(
              currentShelf as ReadingStatusEnum
            )
          ) {
            // try {
            //   const booksByStatusData = cache.readQuery<BooksByStatusQuery>({
            //     query: BooksByStatusDocument,
            //     variables: {
            //       username,
            //       status: currentShelf as ReadingStatusEnum,
            //       page: currentPage,
            //       pageSize,
            //     },
            //   })

            //   if (
            //     booksByStatusData?.userByUsername?.libraryBooksByStatus?.items
            //   ) {
            //     const newItems =
            //       booksByStatusData.userByUsername.libraryBooksByStatus.items
            //         .map((item) => {
            //           if (item?.book?.id && bookIds.includes(item.book.id)) {
            //             return {
            //               ...item,
            //               readingStatus: {
            //                 ...item.readingStatus,

            //                 status: status,
            //               },
            //             }
            //           }
            //           return item
            //         })
            //         .filter((item) => item.readingStatus?.status !== status)

            //     cache.writeQuery({
            //       query: BooksByStatusDocument,
            //       variables: {
            //         username,
            //         status: currentShelf as ReadingStatusEnum,
            //         page: currentPage,
            //         pageSize,
            //       },
            //       data: {
            //         ...booksByStatusData,
            //         userByUsername: {
            //           ...booksByStatusData.userByUsername,
            //           libraryBooksByStatus: {
            //             ...booksByStatusData.userByUsername
            //               .libraryBooksByStatus,

            //             items: newItems,
            //           },
            //         },
            //       },
            //     })

            //     const newStatusData = cache.readQuery<BooksByStatusQuery>({
            //       query: BooksByStatusDocument,
            //       variables: {
            //         username,
            //         status: status as ReadingStatusEnum, // new status
            //         page: currentPage,
            //         pageSize,
            //       },
            //     })

            //     if (
            //       newStatusData?.userByUsername?.libraryBooksByStatus?.items
            //     ) {
            //       const updatedBook =
            //         booksByStatusData.userByUsername.libraryBooksByStatus.items.find(
            //           (item) => bookIds.includes(item?.book?.id as string)
            //         )

            //       if (updatedBook) {
            //         cache.writeQuery({
            //           query: BooksByStatusDocument,
            //           variables: {
            //             username,
            //             status: status as ReadingStatusEnum,
            //             page: currentPage,
            //             pageSize,
            //           },
            //           data: {
            //             ...newStatusData,
            //             userByUsername: {
            //               ...newStatusData.userByUsername,
            //               libraryBooksByStatus: {
            //                 ...newStatusData.userByUsername
            //                   .libraryBooksByStatus,
            //                 items: [
            //                   ...(newStatusData.userByUsername
            //                     .libraryBooksByStatus.items || []),
            //                   updatedBook,
            //                 ],
            //               },
            //             },
            //           },
            //         })
            //       }
            //     }
            //   }
            // } catch (err) {
            //   console.error("Error updating 'BooksByStatus' cache:", err)
            // }
            try {
              // Step 1: Read the current shelf's books from the cache
              const booksByStatusData = cache.readQuery<BooksByStatusQuery>({
                query: BooksByStatusDocument,
                variables: {
                  username,
                  status: currentShelf as ReadingStatusEnum,
                  page: currentPage,
                  pageSize,
                },
              })

              if (
                booksByStatusData?.userByUsername?.libraryBooksByStatus?.items
              ) {
                // Step 2: Extract books to update (those in bookIds)
                const booksToUpdate =
                  booksByStatusData.userByUsername.libraryBooksByStatus.items.filter(
                    (item) => item?.book?.id && bookIds.includes(item?.book?.id)
                  )

                // Step 3: Create updated book entries with the new status
                const updatedBooks = booksToUpdate.map((item) => ({
                  ...item,
                  readingStatus: {
                    ...item.readingStatus,
                    status: status, // New status
                  },
                }))

                // Step 4: Remove the updated books from the old shelf
                const newItems =
                  booksByStatusData.userByUsername.libraryBooksByStatus.items.filter(
                    (item) =>
                      !(item?.book?.id && bookIds.includes(item?.book?.id))
                  )

                // Step 5: Write back the modified old shelf without the updated books
                cache.writeQuery({
                  query: BooksByStatusDocument,
                  variables: {
                    username,
                    status: currentShelf as ReadingStatusEnum,
                    page: currentPage,
                    pageSize,
                  },
                  data: {
                    ...booksByStatusData,
                    userByUsername: {
                      ...booksByStatusData.userByUsername,
                      libraryBooksByStatus: {
                        ...booksByStatusData.userByUsername
                          .libraryBooksByStatus,
                        items: newItems,
                      },
                    },
                  },
                })

                // Step 6: Read the new status shelf from the cache
                const newStatusData = cache.readQuery<BooksByStatusQuery>({
                  query: BooksByStatusDocument,
                  variables: {
                    username,
                    status: status as ReadingStatusEnum, // New status
                    page: currentPage,
                    pageSize,
                  },
                })

                if (
                  newStatusData?.userByUsername?.libraryBooksByStatus?.items
                ) {
                  // Step 7: Avoid adding duplicate books to the new shelf
                  const existingBookIds =
                    newStatusData.userByUsername.libraryBooksByStatus.items.map(
                      (item) => item?.book?.id
                    )
                  const booksToAdd = updatedBooks.filter(
                    (updatedBook) =>
                      !existingBookIds.includes(updatedBook?.book?.id)
                  )

                  if (booksToAdd.length > 0) {
                    // Step 8: Add the updated books to the new status shelf
                    cache.writeQuery({
                      query: BooksByStatusDocument,
                      variables: {
                        username,
                        status: status as ReadingStatusEnum,
                        page: currentPage,
                        pageSize,
                      },
                      data: {
                        ...newStatusData,
                        userByUsername: {
                          ...newStatusData.userByUsername,
                          libraryBooksByStatus: {
                            ...newStatusData.userByUsername
                              .libraryBooksByStatus,
                            items: [
                              ...(newStatusData.userByUsername
                                .libraryBooksByStatus.items || []),
                              ...booksToAdd,
                            ],
                          },
                        },
                      },
                    })
                  }
                }
              }

              // Step 9: Update the book's myReadingStatus to ensure UI consistency
              bookIds.forEach((bookId) => {
                cache.modify({
                  id: cache.identify({ __typename: "Book", id: bookId }),
                  fields: {
                    myReadingStatus: () => status,
                  },
                })
              })
            } catch (err) {
              console.error("Error updating 'BooksByStatus' cache:", err)
            }
          } else {
            // Must be a custom shelf
            try {
              const customShelfData =
                cache.readQuery<BooksByCustomShelfSlugQuery>({
                  query: BooksByCustomShelfSlugDocument,
                  variables: {
                    username,
                    slug: currentShelf,
                    page: currentPage,
                    pageSize,
                  },
                })

              if (customShelfData?.userByUsername?.shelf?.books?.items) {
                const newItems =
                  customShelfData.userByUsername.shelf.books.items.map(
                    (item) => {
                      if (item?.book?.id && bookIds.includes(item?.book?.id)) {
                        return {
                          ...item,
                          readingStatus: {
                            ...item.readingStatus,

                            status: status,
                          },
                        }
                      }
                      return item
                    }
                  )

                cache.writeQuery({
                  query: BooksByCustomShelfSlugDocument,
                  variables: {
                    username,
                    slug: currentShelf,
                    page: currentPage,
                    pageSize,
                  },
                  data: {
                    ...customShelfData,
                    userByUsername: {
                      ...customShelfData.userByUsername,
                      shelf: {
                        ...customShelfData.userByUsername.shelf,
                        books: {
                          ...customShelfData.userByUsername.shelf.books,
                          items: newItems,
                        },
                      },
                    },
                  },
                })
              }
            } catch (err) {
              console.error("Error updating custom shelf data:", err)
            }
          }
        })
      },
    })
  }

  return {
    setStatus,
  }
}

export default useSetReadingStatus
