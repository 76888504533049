import { useParams, useSearchParams } from "next/navigation"
import { useQuery } from "@apollo/client"
import {
  CustomShelfLabelDocument,
  CustomShelfLabelQuery,
  CustomShelfLabelQueryVariables,
} from "@/graphql/generated/types"
import React from "react"
import { PERMANENT_SHELVES } from "@/constants/PERMANENT_SHELVES"

interface ShelfLabelResult {
  label: string
  icon?: React.ReactNode
  loading: boolean
  error?: Error
  isCustomShelf: boolean
  shelfId?: string
}

export const useShelfLabel = (): ShelfLabelResult => {
  const searchParams = useSearchParams()
  const currentShelf = searchParams.get("shelf") || "ALL"
  const username = useParams().username as string

  // Check if the currentShelf is a permanent shelf
  const permanentShelf = PERMANENT_SHELVES.find(
    (shelf) => shelf.value === currentShelf
  ) as (typeof PERMANENT_SHELVES)[0] | undefined

  // Determine if we need to fetch a custom shelf label
  const isCustomShelf = !permanentShelf

  // Always call useQuery, but skip if it's a permanent shelf
  const { data, loading, error } = useQuery<
    CustomShelfLabelQuery,
    CustomShelfLabelQueryVariables
  >(CustomShelfLabelDocument, {
    variables: { username: username, slug: currentShelf },
    skip: !username || !isCustomShelf || !currentShelf,
    fetchPolicy: "cache-first",
  })

  if (permanentShelf) {
    return {
      label: permanentShelf.label,
      icon: permanentShelf.icon,
      loading: false,
      isCustomShelf: false,
    }
  }

  const label =
    data?.userByUsername?.shelf?.name ||
    (loading ? "Loading..." : "Unknown Shelf")

  const shelfId = data?.userByUsername?.shelf?.id || ""

  return {
    label,
    loading,
    error,
    isCustomShelf,
    shelfId,
  }
}
