import getImageUrl from "./utils/getImageUrl"

export const BASE_IMAGE_PUBLIC_URL = "https://images.kaguya.io/ui/public"
export const BOOKS_PER_PAGE_TOP_RATED = 40
export const MOBILE_BOOKS_PER_PAGE_TOP_RATED = 42

export const REVIEWS_PER_PAGE = 5
export const COMMENTS_PER_PAGE = 20
export const LIBRARY_BOOKS_PER_PAGE = 24
export const BOOKS_PER_PAGE = 20

export const DEFAULT_AVATAR_IDS = [
  "001f29d7-8580-4882-adf9-e987176bbf2c",
  "08ecc4b1-2c93-4e10-a0fe-6fd848d0344f",
  "0be7e51f-bd62-4cd3-bc35-c818cbf30f7a",
  "0d97361c-5b66-4365-bac7-3439551bf3fb",
  "11337c05-6144-4ec3-8489-966313503ea5",
  "3426d07b-c435-40ce-8b17-128fa9c62d79",
  "3e2c7ea0-7b41-452c-b43e-66b9f627ec42",
  "5139d24c-c28d-4686-9848-40c0e397e576",
  "58f5e7fa-3ebb-45ab-8526-663d0f91923e",
  "6318b02b-894f-41dc-9044-b98bba2b1f83",
  "78b7eb16-8c8f-4dfa-9682-e74c7b2f3717",
  "8ecaa8c3-a5d8-404d-88d8-9b4ab79a308e",
  "c0ae7462-794d-471b-8cdc-41e71f62029a",
  "c188d1a2-1730-48fe-880b-82a08a713f80",
  "e3a1cf30-391b-44e2-8df1-ab038d1ad7f4",
  "eeea51aa-105e-46e2-8afb-e825397d2b2a",
  "ef991ba8-4f35-4fc2-b301-966e5f6447ff",
]

export const DEFAULT_BANNER_ID = "4c2a6449-4a28-42f3-b090-7901e78307e8"

export const DEFAULT_AVATAR_URLS = DEFAULT_AVATAR_IDS.map((id) =>
  getImageUrl(id, "avatar")
)
